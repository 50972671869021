import firebase, { db, doc, getDoc } from "../firebase";

export default class Book {

  // 全体的にモデルをキレイにする
  constructor(title, author_name, owner_user, tweets = [], id = 0) {
    this.title = title;
    this.author_name = author_name;
    this.owner_user = owner_user;
    this.tweets = tweets
    this.id = id
  }

  async addTweet(text) {
    await db.collection("books").doc(this.id).collection("tweets").add({
      text: text,
      createdAt: new Date()
    })
  }

  static fromDict(dict) {
    return new Book(
      dict.title,
      dict.author_name,
      dict.owner_user,
      dict.tweets,
      dict.id
    )
  }

  static async new(title, author) {
    const object = await db.collection("books").add({
      title: title,
      author: author,
      createdAt: new Date()
    })
    return object.id
  }

  static async fetchBooksByLoginUser() {
    const book_collection = db.collection("books")
    const book_documents = await book_collection.orderBy("createdAt").get()
    const books = [];

    book_documents.forEach(book_document => {
      books.push({ ...book_document.data(), id: book_document.id })
    })

    return books
  }

  static async fetchBookByBookId(book_id) {
    const book_collection = await db.collection("books");
    const book_document = await book_collection.doc(book_id).get();
    const book_tweets_document = await book_document.ref.collection("tweets").orderBy("createdAt", "desc").get()
    const tweets = [];

    book_tweets_document.forEach(tweet_document => {
      tweets.push({ ...tweet_document.data(), id: tweet_document.id })
    })
    const book = Book.fromDict({ ...book_document.data(), ...{ "tweets": tweets }, ...{ "id": book_id } })
    return book
  }


}