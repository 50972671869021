import { createContext, useState, useContext, useEffect } from 'react';
import firebase, { auth, db } from '../firebase';

const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState('');

  const value = {
    user,
  };

  useEffect(() => {
    const unsubscribed = auth.onAuthStateChanged((user) => {
      if (!user) return;
      setUser(user);
      db.collection("users").doc(user.uid).set({
        uid: user.uid,
        user_name: user.displayName,
        icon_url: user.photoURL,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
      });

    });
    return () => {
      unsubscribed();
    };
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
