import firebase, { db } from '../firebase';
import React from 'react';

import { Redirect } from 'react-router';


export default class Login extends React.Component {
    constructor(props) {
        super(props);


    }

    handleClick(e) {
        e.preventDefault();
        const provider = new firebase.auth.TwitterAuthProvider();
        firebase.auth().signInWithPopup(provider)
            .then((result) => {
                console.log(result);



            }).catch((error) => {
                console.log(error);
            });
    }

    render() {
        if (this.props.user && this.props.user.uid) {
            return (<Redirect to='/bookshelf' />)
        }
        return (
            <button onClick={(e) => this.handleClick(e)}> ログイン</button>
        )
    }
}

