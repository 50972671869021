import React, { useState } from 'react';
import Book from '../models/Book'

export default class BookTweetForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            text: '',
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event) {
        event.preventDefault();
        await this.props.book.addTweet(this.state.text)
        this.setState({ "text": "" })
        await this.props.reload_book_info()
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <label>
                    本文:
                    <input type="text" value={this.state.text} onChange={(input) => this.setState({ text: input.target.value })} />
                </label>
                <input type="submit" value="Submit" />
            </form>
        );
    }

}