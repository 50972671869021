import React from 'react';
import Book from '../models/Book'
import { withRouter } from "react-router";

class BookRegistrationForm extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            title: '',
            author_name: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event) {
        event.preventDefault();
        const id = await Book.new(
            this.state.title,
            this.state.author_name
        )

        this.props.history.push(`/books/${id}/timeline`)
    }


    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <label>
                    書名:
                    <input type="text" onChange={(input) => this.setState({ title: input.target.value })} />
                </label>
                <label>
                    著者:
                    <input type="text" onChange={(input) => this.setState({ author_name: input.target.value })} />
                </label>
                <input type="submit" value="Submit" />
            </form>
        );
    }

}


export default withRouter(BookRegistrationForm)