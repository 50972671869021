import React, { useState, useEffect } from 'react';

import { useAuthContext } from '../contexts/Auth';
import Book from '../models/Book';

import BookTweetForm from '../components/BookTweetForm';

function BookTimeLine(book_id) {
    const params = book_id.match.params

    const { user } = useAuthContext();
    const [book, setBook] = useState([]);
    const [tweets, setTweets] = useState([]);


    useEffect(() => {
        const f = async () => {
            const book = await Book.fetchBookByBookId(params.id);
            setBook(() => book);

            setTweets(() => book.tweets)
        };
        f();
    }, []);

    async function reload_book_info() {
        const book = await Book.fetchBookByBookId(params.id);
        setBook(() => book);

        setTweets(() => book.tweets)
    }


    return (
        <div >
            {book.title}
            <BookTweetForm book={book} reload_book_info={reload_book_info} />
            {tweets.map((tweet) => <li> {tweet.text} </li>)
            }
        </div>
    )

}

export default BookTimeLine;