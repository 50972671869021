import React, { useState, useEffect } from 'react';
import {
  Link,
} from "react-router-dom";

import { useAuthContext } from '../contexts/Auth';
import Book from '../models/Book';

import BookRegistrationForm from '../components/BookRegistrationForm';
import BookTimeLine from './BookTimeLine';
function BookShelf() {

  const { user } = useAuthContext();
  const [books, setBooks] = useState([]);

  useEffect(() => {
    const f = async () => {
      const books = await Book.fetchBooksByLoginUser();
      setBooks(() => books);
    };
    f();
  }, []);

  return (
    <div className="App">
      {user.uid}/
      {books.map((book) => <li> <Link to={`books/${book.id}/timeline`} >{book.title}</Link></li>)
      }
      <BookRegistrationForm user_id={user.uid} />

    </div >
  );
}

export default BookShelf;
