import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";


import Login from './components/Twitter';
import { AuthProvider, useAuthContext } from './contexts/Auth';
import Book from './models/Book';
import BookShelf from './pages/BookShelf';
import BookTimeLine from './pages/BookTimeLine';

function App() {

  return (
    <Router>
      <div>
        <AuthProvider>
          <nav>
            <ul>
              <li>
                <Link to="/">Top </Link>
              </li>
              <li>
                <Link to="/bookshelf">bookshelf </Link>
              </li>
            </ul>
          </nav>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/bookshelf" component={BookShelf}>
              <BookShelf />
            </Route>
            <Route path="/books/:id/timeline" component={BookTimeLine} />
            <Route path="/">
              <Top />
            </Route>

          </Switch>
        </AuthProvider>
      </div>
    </Router>
  );
}


function Top() {
  const { user } = useAuthContext();

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <Login user={user} />
      </header>
    </div>
  );
}

export default App;